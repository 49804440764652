<template>
<section class="px-3">
    <n-alert class="rounded-lg" type="warning" size="small">
        <template #header>
            <n-collapse arrow-placement="right">
                <n-collapse-item>
                    <template #header>
                        <div class="text-[10px] leading-4" v-html="parsed[0]"></div>
                    </template>
                    <div
                        class="notes text-[10px] leading-4"
                        v-html="parsed.slice(1).join('')">
                    </div>
                </n-collapse-item>
            </n-collapse>
        </template>
    </n-alert>

    <div v-if="!init" class="flex h-full justify-center items-center py-20">
        <n-spin size="small" />
    </div>
    <div v-else>
        <super-table
            :type="type"
            :filters="filters"
            :actions="actions"
            :refFilters="refFilters"
            :loading="loadings.table"
            :pager="marketplace.pager"
            :columns="marketplace.columns"
            :records="marketplace.records"
            :filtersChanged="filtersChanged"
            columnsStorageName="__columns_marketplace-buy"
            @getData="getData"
            @sortColumn="sortColumn"
            @reset-filters="resetFilters"
            @applyFilters="changeFilter">
            <template #tableTitle>
                <div class="text-sm text-left mb-2">
                    {{ marketplace.localization?.['marketplace/buy/grid/title'] }}:
                    <span class="text-main font-semibold text-md">{{ marketplace.total }}</span>
                </div>
            </template>
        </super-table>
    </div>
    <bot-review v-if="marketplace.localization" :show="showModal" />
</section>
</template>

<script>
// general
import general from './general';

// naive-ui
import { NSpin, NCard, NSkeleton, NDivider } from 'naive-ui';

// naive-ui
import {
    NAlert,
    NCollapse,
    NCollapseItem } from 'naive-ui';

// components
import SuperTable from '@components/super-table/mobile.vue';
import FilterCoins from '@components/filters/coins.vue';
import GridTable from '@components/base/grid-table.vue';
import BotReview from '@components/bot-review/mobile.vue';
import GridSettings from '@components/base/grid-settings.vue';
import FilterExchanges from '@components/filters/exchanges.vue';
import FilterChangedDate from '@components/filters/changedDate.vue';

export default {
    name: 'marketplace-buy-mobile',
    components: {
        NCard,
        NSpin,
        NAlert,
        NDivider,
        BotReview,
        NSkeleton,
        GridTable,
        NCollapse,
        SuperTable,
        FilterCoins,
        GridSettings,
        NCollapseItem,
        FilterExchanges,
        FilterChangedDate,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.buy-info {
  :deep(p):not(:first-child) {
    margin-top: 14px;

    &[style='color:red'] {
      color: rgb(208, 58, 82) !important;
    }
  }

  :deep(b) {
    color: #4949D9;
  }

  :deep(a) {
    text-decoration: underline;
  }

  :deep(ul) {
    margin-top: 14px;
    list-style: circle;
    padding-left: 24px;
  }
}
</style>